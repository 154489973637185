import React from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import Header from 'layouts/shared/header';
import Aside from 'layouts/shared/aside';
import HeaderUserMenu from 'layouts/shared/header/components/header-user-menu';

const Layout = ({ routes, children }) => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  // Close the drawer on location change
  React.useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <LayoutWrapper>
      <LayoutHeader>
        <Header onDrawerClick={onOpen} isOpen={isOpen}>
          <Text
            as={ReactLink}
            to={{
              pathname: `/pricing`,
              state: { background: location },
            }}
            size="md"
            color={'pink.500'}
          >
            Pricing
          </Text>
          <HeaderUserMenu />
        </Header>
      </LayoutHeader>

      <LayoutAside>
        <Aside routes={routes} isFixed />
      </LayoutAside>

      <LayoutAsideDrawer
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        btnRef={btnRef}
      >
        <Aside routes={routes} />
      </LayoutAsideDrawer>

      <LayoutMain>
        <LayoutMainScroll>{children}</LayoutMainScroll>
      </LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    display={'grid'}
    my={0}
    mx={'auto'}
    minH={'100vh'}
    maxW={'2560px'}
    gridTemplateColumns={'auto minmax(0, 1fr)'}
    gridTemplateRows={'0fr 0fr 1fr 0fr'}
    backgroundColor={'#1A1C22'}
    color={'white'}
    data-testid="layout-wrapper"
  >
    {children}
  </Box>
);

const LayoutHeader = ({ children }) => (
  <Box
    width={'100%'}
    gridColumn={'1/3'}
    gridRow={'1/2'}
    position={'sticky'}
    top={0}
    zIndex={3}
    bg={'#17161D'}
    data-testid="header"
  >
    {children}
  </Box>
);

const LayoutAside = ({ children }) => (
  <Box
    width={'280px'}
    gridRow={'2/5'}
    gridColumn={'1/2'}
    zIndex={1}
    display={['none', 'none', 'none', 'block']}
    data-testid="aside"
  >
    {children}
  </Box>
);

const LayoutAsideDrawer = ({ children, onClose, isOpen, btnRef }) => (
  <Box zIndex={1} data-testid="aside">
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      finalFocusRef={btnRef}
      size="xs"
    >
      <DrawerOverlay />
      <DrawerContent width={'100px'} bg={'#383648'}>
        <DrawerCloseButton />
        <DrawerHeader color="white">Clippy</DrawerHeader>
        <Divider />
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  </Box>
);

const LayoutMain = ({ children }) => (
  <Box
    width={'100%'}
    gridRow={'3/4'}
    gridColumn={['1/3', '2/3']}
    zIndex={1}
    position={'relative'}
    data-testid="main"
  >
    {children}
  </Box>
);

const LayoutMainScroll = ({ children }) => (
  <Box
    width={'100%'}
    height={'100%'}
    position={'absolute'}
    overflow={'auto'}
    data-testid="layout-main-scroll"
  >
    {children}
  </Box>
);

export default Layout;
